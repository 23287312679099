import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setAuthHeader, url } from "../Api";

const initialState = {
    items:localStorage.getItem('works')?JSON.parse(localStorage.getItem('works')):[],
    status:''
}
export const getWorks = createAsyncThunk(
    'works/getWorks',
    async()=>{
        try {
          const response = await axios.get(`${url}/works`,setAuthHeader());
          localStorage.setItem('works',JSON.stringify(response.data.resp));
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)

const worksSlice = createSlice({
    name:'works',
    initialState,
    reducers:{
        loadWorks(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:builder=>{
        builder.addCase(getWorks.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getWorks.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getWorks.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    } 
})

export const{loadWorks} = worksSlice.actions;
export default worksSlice.reducer;