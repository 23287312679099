import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getVisitor = createAsyncThunk(
    'visitor/getVisitor',
    async()=>{
        try {
            const response = await axios.get(`${url}/visitor`)
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const visitorSlice = createSlice({
    name:'visitor',
    initialState,
    reducers:{
        loadVisitor(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:builder=>{
        builder.addCase(getVisitor.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getVisitor.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getVisitor.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export const{loadVisitor} = visitorSlice.actions;
export default visitorSlice.reducer;