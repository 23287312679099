import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Form, FormControl, Row, 
        Spinner,ListGroup, ListGroupItem, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FaPlus } from 'react-icons/fa';
import { url } from '../../../Redux/Api';
import './Dashboard.css'

const Dashboard = props => {
    const user = useSelector(state=>state.auth.user);
    const users = useSelector(state=>state.users.items);
    const teachings = useSelector(state=>state.teachings.items);
    const contacts = useSelector(state=>state.contacts.items);
    const blogs = useSelector(state=>state.blogs.items);
    const courses = useSelector(state=>state.courses.items);
    const subscriptions = useSelector(state=>state.subscriptions.items);
    const enrolments = useSelector(state=>state.enrolments.items);
    const visits = useSelector(state=>state.visitors.items);
    const[blogTitle, setBlogTitle] = useState('');
    const[blogCourse, setBlogCourse] = useState('');
    const[blogBody, setBlogBody] = useState('')
    const[blogSrc, setBlogSrc] = useState('')
    const[teachingReplyText, setTeachingReplyText] = useState('')

    
    const[active, setActive] = useState('courses');
    const[title, setTitle] = useState('');
    const[partTimeDuration, setPartTimeDuration] = useState(36);
    const[regularDuration, setRegularDuration] = useState(12);
    const[partTimeMeetingDays, setPartTimeMeetingDays] = useState('Saturdays');
    const[partTimeMeetingTime, setPartTimeMeetingTime] = useState('3pm-6pm')
    const[regularMeetingDays, setRegularMeetingDays] = useState('Mondays, Wednesdays, Fridays');
    const[regularMeetingTime, setRegularMeetingTime] = useState('4pm-6pm');
    const[description, setDescription] = useState('');
    const[cost, setCost] = useState(0);
    const[venue, setVenue] = useState('Retiny Event Space');
    const[owner, setOwner] = useState('');
    const[newCode, setNewCode] = useState('');
    const[newCodetitle, setNewCodeTitle] = useState('');
    const[codes, setCodes] = useState([]);
    const[newState, setNewState] = useState({status:'',data:''});
    const[loading, setLoading] = useState(false);
    const[imagePreview, setImagePreview] = useState('');
    const[spaceCategory,setSpaceCategory] = useState('');
    const[maxParticipant,setMaxParticipant] = useState(0);
    const[spaceCost,setSpaceCost] = useState(0);

    function handleAddCode(){
      setCodes([...codes,{title:newCodetitle,code:newCode}]);
      setNewCode('');
      setNewCodeTitle('')
    }
    function handleTeachingApproval(e){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/teaching-approval`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({teachingId:e._id})
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleteachingReply(e){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/teaching-response`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    teachingReplyText,teachingId:e._id
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleCreateSpace(e){
      e.preventDefault()
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/create-space`,{
                  mode:'cors',
                  method:'post',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    spaceCategory,maxParticipant,spaceCost
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleUnSubscriptionConfirmation(e){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/unconfirm-subscription`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    subscriptionId:e._id
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handleSubscriptionConfirmation(e){
      setLoading(true)
      async function fetchData(){
              await fetch(`${url}/confirm-subscription`,{
                  mode:'cors',
                  method:'put',
                  headers:{
                      'Content-Type':'application/json',
                      'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                  },body:JSON.stringify({
                    subscriptionId:e._id
                  })
              }).then(res=>res.json())
              .then(resp=>{
                  if(resp.resp){
                      setLoading(false)
                      setNewState({status:'success',data:resp.resp})
                      return window.location.reload()
                  }else if(resp.err){
                      setLoading(false)
                      return setNewState({status:'failed',data:resp.err})
                  }else{
                      setLoading(false)
                      return setNewState({status:'failed',data:'Something went wrong.'})
                  }
              }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
          }
          fetchData();
  }
    function handlePaymentConfirmation(e){
        setLoading(true)
     
        async function fetchData(){
                await fetch(`${url}/confirm-payment`,{
                    mode:'cors',
                    method:'put',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({
                        enrolmentId:e._id
                    })
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        setLoading(false)
                        setNewState({status:'success',data:resp.resp})
                        return window.location.reload()
                    }else if(resp.err){
                        setLoading(false)
                        return setNewState({status:'failed',data:resp.err})
                    }else{
                        setLoading(false)
                        return setNewState({status:'failed',data:'Something went wrong.'})
                    }
                }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
            }
            fetchData();
   
      
    }

    function handleCreateBlog(e){
        e.preventDefault()
        setLoading(true)
        async function fetchData(){
                await fetch(`${url}/create-blog`,{
                    mode:'cors',
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({
            blogTitle,blogBody,blogSrc,blogCourse,codes
                    })
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        setLoading(false)
                        setNewState({status:'success',data:resp.resp})
                        return window.location.reload()
                    }else if(resp.err){
                        setLoading(false)
                        return setNewState({status:'failed',data:resp.err})
                    }else{
                        setLoading(false)
                        return setNewState({status:'failed',data:'Something went wrong.'})
                    }
                }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
            }
            fetchData();
    }
    function handleSubmit(e){
        e.preventDefault()
        setLoading(true)
        async function fetchData(){
                await fetch(`${url}/create-course`,{
                    mode:'cors',
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':'Bearer '+JSON.parse(localStorage.getItem('token'))
                    },body:JSON.stringify({
                        title,regularDuration,partTimeDuration,venue,
partTimeMeeting:{partTimeMeetingDays,partTimeMeetingTime},
regularMeeting:{regularMeetingDays,regularMeetingTime},cost,
description,
src:imagePreview,owner
                    })
                }).then(res=>res.json())
                .then(resp=>{
                    if(resp.resp){
                        setLoading(false)
                        setNewState({status:'success',data:resp.resp})
                        return window.location.reload()
                    }else if(resp.err){
                        setLoading(false)
                        return setNewState({status:'failed',data:resp.err})
                    }else{
                        setLoading(false)
                        return setNewState({status:'failed',data:'Something went wrong.'})
                    }
                }).catch(err=>{setNewState({status:'failed',data:err.message});setLoading(false)})
            }
            fetchData();
    }
    const handleFile = async (a)=>{
      const data = a.target.files[0];
      const base64 = await Converter(data)
      setImagePreview(base64)
      console.log('file',data);
    }
    const Converter = (e)=>{
      return new Promise((resolve,reject)=>{
        const reader = new FileReader()
        reader.readAsDataURL(e)
        reader.onload = ()=>{
          resolve(reader.result)
        }
        reader.onerror = (error)=>{
          reject(error)
        }
      })
    }
    
    return <Container className='dashboard-container'>
        <h3>{newState.data}</h3>
      {/* <Button onClick={handleSubscriptionConfirmation}>Confirm Subscription</Button>
      <Button onClick={handleUnSubscriptionConfirmation}>Remove Subscription</Button> */}
        <Tabs
      defaultActiveKey={active==='blogs'?'blogs':active==='blog'?'blog':'blogs'}
      id="uncontrolled-tab-example"
      className="mb-3"
    ><Tab eventKey="space" title="Space Form" onClick={()=>setActive('space')}>
    <div style={{margin:'40px auto'}}>
            <h3>Create Space</h3>
    </div>
    {newState.data.length>0&&<p>{newState.data}</p>}
    <Form onSubmit={handleCreateSpace} autoComplete='true'>
    <Col>
<Row xl={6} md={4} className='code-block'>

<span style={{color:'gray',marginTop:'20px'}}>Max Participant</span>
    <Form.Control 
    onChange={e=>setMaxParticipant(e.target.value)}
    />
<span style={{color:'gray',marginTop:'20px'}}>Cost</span><FormControl 
    onChange={e=>setSpaceCost(e.target.value)}
    />
    <span style={{color:'gray',marginTop:'20px'}}>Space Category</span><FormControl 
    // placeholder='Course detailed description.'
    onChange={e=>setSpaceCategory(e.target.value)}
    />
    
</Row>
    </Col>
    {loading?<Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner>:<Button type='submit'>Create</Button>}
    </Form>
  </Tab>

        <Tab eventKey="blog" title="Blog Form" onClick={()=>setActive('blog')}>
        <div style={{margin:'40px auto'}}>
                <h3>Create New Blog</h3>
        </div>
        {newState.data.length>0&&<p>{newState.data}</p>}
        <Form onSubmit={handleCreateBlog} autoComplete='true'>
        <Col>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Blog Course</span><FormControl required
        // placeholder='Course title'
        onChange={e=>setBlogCourse(e.target.value)}
        />
        </Row>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Blog Title</span><FormControl required
        // placeholder='Course title'
        onChange={e=>setBlogTitle(e.target.value)}
        />
        </Row>

<Row xl={6} md={4}>

        <Form.Label>Blog Body</Form.Label>
        <Form.Control as="textarea" rows={3} 
        onChange={e=>setBlogBody(e.target.value)}
        cols={50}/>
</Row>
<Row xl={6} md={4} className='code-block'>
  
<span style={{color:'gray',marginTop:'20px'}}>Code {'< />'}</span><FormControl 
        // placeholder='Course detailed description.'
        as="textarea" rows={5}
        onChange={e=>setNewCode(e.target.value)}
        value={newCode}
        />
        <span style={{color:'gray',marginTop:'20px'}}>Code Title</span><FormControl 
        // placeholder='Course detailed description.'
        onChange={e=>setNewCodeTitle(e.target.value)}
        value={newCodetitle}
        />
        <div className='add-code'>
        <FaPlus onClick={handleAddCode}/>
        </div>
        
</Row>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Course Thumbnail</span><FormControl 
        // placeholder='Course thumbnail.'
        onChange={e=>setBlogSrc(e.target.value)}
        />
        </Row>
        </Col>
        {loading?<Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>:<Button type='submit'>Create</Button>}
        </Form>
      </Tab>
      <Tab eventKey="blogs" title="Blogs"  onClick={()=>setActive('blogs')}>
            {blogs?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.blogTitle}</ListGroupItem>
      </ListGroup>
                )
            })}
      </Tab>
      <Tab eventKey="teachings" title="Teaching Requests"  onClick={()=>setActive('teachings')}>
            {teachings?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.message}</ListGroupItem>
        <ListGroupItem>Company/Business Name: {item?.company}</ListGroupItem>
        <ListGroupItem>{item?.replied?item?.response:
      <Form>
        <FormControl type='textarea' placeholder='Reply...' onChange={e=>setTeachingReplyText(e.target.value)} />
        <Button onClick={()=>handleteachingReply(item)}>Reply</Button>
      </Form>
      }</ListGroupItem>
        {!item?.approved&&item?.replied&&<Button onClick={()=>handleTeachingApproval(item)}>Approve</Button>}
      </ListGroup>
                )
            })}
      </Tab>
    </Tabs>
    <div style={{marginBottom:'100px'}} />
        <Tabs
      defaultActiveKey={active==='completed'?'completed':active==='running'?'running':'courses'}
      id="uncontrolled-tab-example"
      className="mb-3"
    >
        <Tab eventKey="courses" title="Course Form" onClick={()=>setActive('courses')}>
        <div style={{margin:'40px auto'}}>
                <h3>Create New Course</h3>
        </div>
        {newState.data.length>0&&<p>{newState.data}</p>}
        <Form onSubmit={handleSubmit} autoComplete='true'>
        <Col>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Title</span><FormControl required
        // placeholder='Course title'
        onChange={e=>setTitle(e.target.value)}
        />
        </Row>

<Row xl={6} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Course Description</span><FormControl required
        // placeholder='Course detailed description.'
        onChange={e=>setDescription(e.target.value)}
        />
</Row>
<Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Cost</span><FormControl required
        // placeholder='Cost.'
        type='number'
        min={0}
        onChange={e=>setCost(e.target.value)}
        />
        </Row>
        <Row xl={6} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Venue</span><FormControl required
        // placeholder='Lessons Venue'
        value={venue}
        disabled
        onChange={e=>setVenue(e.target.value)}
        />
</Row>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Course Thumbnail</span><FormControl required
        type='file'
        onChange={handleFile}
        />
        {
          imagePreview.length>0&&
          <Row>
          <img src={imagePreview} alt='' height={'auto'} width={'100%'} />
        </Row>
        }
        </Row>
        <Row xl={6} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Company Name</span><FormControl required
        // placeholder='Course thumbnail.'
        onChange={e=>setOwner(e.target.value)}
        />
        </Row>
<Row>
<Col md={6} style={{padding:'20px'}}>
<div style={{margin:'40px auto',borderBottom:'2px solid var(--primary-1)'}}>
                <h3>Regular Section</h3>
        </div>
        <Row xl={12} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Duration</span><FormControl type='number' min={0} required
                // placeholder='The duration of the training.'
                onChange={e=>setRegularDuration(e.target.value)}
                value={regularDuration}
                />
        </Row>
<Row xl={12} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Meeting Days</span><FormControl required
        // placeholder='Regular meeting days'
        value={regularMeetingDays}
        onChange={e=>setRegularMeetingDays(e.target.value)}
        />
</Row>
<Row xl={12} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Meeting Time</span><FormControl required
        // placeholder='Regular meeting time'
        value={regularMeetingTime}
        onChange={e=>setRegularMeetingTime(e.target.value)}
        />
</Row>
</Col>
<Col md={6} style={{padding:'20px'}}>
<div style={{margin:'40px auto',borderBottom:'2px solid var(--primary-1)'}}>
                <h3>Part-Time Section</h3>
        </div>
        
<Row xl={12} md={4}>
<span style={{color:'gray',marginTop:'20px'}}>Duration</span><FormControl type='number' min={0} required
        // placeholder='Part Time Duration'
        
        value={partTimeDuration}
        onChange={e=>setPartTimeDuration(e.target.value)}
        />
</Row>
        <Row xl={12} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Meeting Days</span><FormControl required
        // placeholder='Part-time meeting days'
        
        value={partTimeMeetingDays}
        onChange={e=>setPartTimeMeetingDays(e.target.value)}
        />
        </Row>
        <Row xl={12} md={4}>
        <span style={{color:'gray',marginTop:'20px'}}>Meeting Time</span><FormControl required
        // placeholder='Part-time meeting time'
        
        value={partTimeMeetingTime}
        onChange={e=>setPartTimeMeetingTime(e.target.value)}
        />
        </Row>
</Col>
</Row>
        </Col>
        {loading?<Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>:<Button type='submit'>Create</Button>}
        </Form>
      </Tab>
      <Tab eventKey="enrolments" title="Enrolments"  onClick={()=>setActive('enrolments')}>
            {enrolments?.map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.title}</ListGroupItem>
      {item?.scholarship&&<ListGroupItem>Scholar</ListGroupItem>}
      {item?.done&&<ListGroupItem>Completed</ListGroupItem>}
      {
        !item?.paid&&<>
        {
                loading?<Spinner/>:<ListGroupItem><Button onClick={()=>handlePaymentConfirmation(item)}>Confirm Payment</Button></ListGroupItem>
        }
        </>
      }

      </ListGroup>
                )
            })}
      </Tab>
      <Tab eventKey="completed" title="Completed Courses" onClick={()=>setActive('completed')}>
      <ListGroup>
            {courses?.filter(filt=>filt?.users?.includes(user?.email)).map((item,index)=><ListGroupItem key={index}>{item?.title}</ListGroupItem>)}
        </ListGroup>
      </Tab>
    </Tabs>
        
    <div style={{marginBottom:'100px'}} />
    <Tabs
      defaultActiveKey={active==='completed'?'completed':active==='running'?'running':'courses'}
      id="uncontrolled-tab-example"
      className="mb-3"
    >
        
      <Tab eventKey="visits" title="Visitors" onClick={()=>setActive('visits')}>
      {visits?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.addresses}</ListGroupItem>
        <ListGroupItem>IPv{item?.family}</ListGroupItem>
        <ListGroupItem>{item?.info?.CountryCode}</ListGroupItem>
        <ListGroupItem>{item?.info?.CountryName}</ListGroupItem>
        <ListGroupItem>{item?.info?.StreetAddress}</ListGroupItem>
        <ListGroupItem>{item?.info?.City}</ListGroupItem>
        <ListGroupItem>{item?.info?.RegionName}</ListGroupItem>
        <ListGroupItem>{item?.info?.ZipCode}</ListGroupItem>
        {item.dates.map((itm,ind)=>{
                return(
                        <ListGroup key={ind}>
                                <ListGroupItem>Visits</ListGroupItem>
                                <ListGroupItem className='list-group-item'><span style={{color:'gray',marginTop:'20px'}}>Day</span><span style={{color:'gray',marginTop:'20px'}}>{moment(itm?.toString()).format('MMM DD, yyyy')}</span></ListGroupItem>
                        </ListGroup>
                )
        })}
        
      </ListGroup>
                )
            })}
      </Tab>
      
        <Tab eventKey="subscriptions" title="Subscriptions" onClick={()=>setActive('subscriptions')}>
      {subscriptions?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.company}</ListGroupItem>
        <ListGroupItem>{item?.participants?.length} Participants</ListGroupItem>
        {item?.subscribed?
        <Button onClick={()=>handleUnSubscriptionConfirmation(item)}>Remove Subscription</Button>:<Button onClick={()=>handleSubscriptionConfirmation(item)}>Confirm Subscription</Button>}
      </ListGroup>
                )
            })}
      </Tab>
      <Tab eventKey="users" title="Users" onClick={()=>setActive('users')}>
      {users?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.country.label}</ListGroupItem>
        <ListGroupItem>{item?.firstName}</ListGroupItem>
      </ListGroup>
                )
            })}
      </Tab>

      <Tab eventKey="contacts" title="Contacts" onClick={()=>setActive('contacts')}>
      {contacts?.filter(filt=>filt).map((item,index)=>{
                return(
      <ListGroup key={index}>
        <ListGroupItem>{item?.name}</ListGroupItem>
        <ListGroupItem>{item?.email}</ListGroupItem>
        <ListGroupItem>{item?.phone}</ListGroupItem>
        <ListGroupItem>{item?.message}</ListGroupItem>
        <ListGroupItem>{moment(item?.date?.toString()).format('MMM DD, yyyy')}</ListGroupItem>
      </ListGroup>
                )
            })}
      </Tab>
    </Tabs>
    </Container>;
};

Dashboard.propTypes = {};

export { Dashboard };