import { useEffect, useState } from 'react'
export default function useLocalStorage(key, initialValue) {
  let item = `${key}-workbook`

  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(item)
    if (jsonValue != null) return JSON.parse(jsonValue)

    if (typeof initialValue === 'function') {
      return initialValue()
    } else {
      return initialValue
    }
  })

useEffect(() => {
  localStorage.setItem(item, JSON.stringify(value))
}, [item, value])

  return [value, setValue]
}
