import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Anchor, Button, ButtonGroup, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
// import { courses } from '../../assets/JSON';
import { url } from '../../assets/Api';
import { useDispatch, useSelector } from 'react-redux';
import { loadCourse } from '../../Redux/Slices/courseSlice';

const Course = props => {
    const{courseId} = useParams();
    const[course, setCourse] = useState({});
    const courses = useSelector(state=>state.courses.items);
    const[newState, setNewState] = useState({status:'',data:''});
    const[category, setCategory] = useState('');
const user = useSelector(state=>state.auth.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        if(courseId){
            let a = courses.filter(filt=>filt?._id.toString()===courseId.toString())
            setCourse(a[0])
            dispatch(loadCourse(a[0]))
            console.log('yo',a[0])
    }
    },[courseId]);
    
    return <div className='course-container'>
   <div className="card">
      <img src={course?.src} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title">{course?.title}</h5>
        <p className="card-text">{course?.description}</p>
        <p className="cost">${course?.cost}</p>
        <hr/>
        <h1 className='category'>Regular</h1>
        <p className="card-text">{course?.regularDuration} Weeks</p>
        <p className="card-text">{`${course?.regularMeeting?.regularMeetingDays} ${course?.regularMeeting?.regularMeetingTime}`}</p>
        <Button className='contact-btn' onClick={()=>navigate(`/payment-area/course/${course?.company?course?.company:'course-creators'}/${course?._id}`)}>Enrol</Button>
        <hr />
        <h1 className='category'>Part Time</h1>
        <p className="card-text">{course?.partTimeDuration} Weeks</p>
        <p className="card-text">{`${course?.partTimeMeeting?.partTimeMeetingDays} ${course?.partTimeMeeting?.partTimeMeetingTime}`}</p>
        <Button className='contact-btn' onClick={()=>navigate(`/payment-area/part-time/${course?.company?course?.company:'course-creators'}/${course._id}`)}>Enrol</Button>
      </div>
    </div>
</div>
};

Course.propTypes = {};

export { Course };