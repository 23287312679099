import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items:localStorage.getItem('my-courses')?JSON.parse(localStorage.getItem('my-courses')):[],
    status:''
}

const myCoursesSlice = createSlice({
    name:'myCourses',
    initialState,
    reducers:{
        loadMyCourses(state,action){
            const item = action.payload;
            if(item?.lenght>0){
                localStorage.setItem('myCourses',JSON.stringify(item))
                return{
                    ...state,items:item
                }
            }

        }
    }
})

export const{loadMyCourses} = myCoursesSlice.actions
export default myCoursesSlice.reducer;