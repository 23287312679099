import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:[],
    status:[]
}
export const getApps = createAsyncThunk(
    'apps/getApps',
    async()=>{
        try {
          const response = await axios.get(`${url}/app-setting`)  
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)
const appsSlice = createSlice({
    name:'apps',
    initialState,
    reducers:{},
    extraReducers:builder=>{
        builder.addCase(getApps.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getApps.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getApps.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
}
})

export default appsSlice.reducer;