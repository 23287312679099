import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lesson:localStorage.getItem('lesson')&&JSON.parse(localStorage.getItem('lesson')),
    status:''
}
const lessonSlice = createSlice({
    name:'lesson',
    initialState,
    reducers:{
        loadLesson(state,action){
            const item = action.payload;
            if(item){
                localStorage.setItem('lesson',JSON.stringify(item))
                return {
                    lesson:item,
                    loadLesson:true
                }
            }

        }
    }
})

export const {loadLesson} = lessonSlice.actions;
export default lessonSlice.reducer