import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
// import { space } from '../../assets/JSON';
import './Spaces.css'
import { Anchor, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, FormControl, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GooglePayButton from '@google-pay/button-react';
import { url } from '../../Redux/Api';


const SpaceDetail = props => {
const{spaceId} = useParams();
const spaces = useSelector(state=>state.spaces.items);
const user = useSelector(state=>state.auth.user);
const[space, setSpace] = useState({});
const[loading, setLoading] = useState(false);
const[newState, setNewState] = useState({status:'',data:''});
const[company, setCompany] = useState('');

const navigate = useNavigate();
const handelSubscription = ()=>{
  return navigate(`/payment-area/space/${company}/${space?._id}`)
}
useEffect(()=>{
    if(spaces?.length>0){
      let a = spaces?.filter(filt=>filt._id.toString()===spaceId.toString());
    setSpace(a[0]);
    }
},[spaceId]);

return(<Container className='spaces-container'>
  <Card style={{margin:'5px'}}>
<CardBody className="card-body">
  <CardTitle className="card-title">{space?.spaceCategory}</CardTitle>
  <CardText className="card-text-space">${space?.spaceCost}</CardText>
  <CardText className="card-text-space">{space?.maxParticipants} participants</CardText>
  <CardText className="card-text-space">{space?.description}</CardText>
  <FormControl
  placeholder='Company'
  onChange={e=>setCompany(e.target.value)}
  />
  {
    loading?<Spinner/>:<>
    {
      user?<Button onClick={handelSubscription}>Subscribe</Button>:<Link to='/login'>Login</Link>
    }
    </>
  }
  {/* <GooglePayButton
  environment="TEST"
  paymentRequest={{
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'example',
            gatewayMerchantId: 'exampleGatewayMerchantId',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: '12345678901234567890',
      merchantName: 'retiny technology ltd',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: space?.spaceCost,
      currencyCode: 'USD',
      countryCode: 'US',
    },
  }}
  onLoadPaymentData={paymentRequest => {
    console.log('load payment data', paymentRequest);
  }}
/> */}
</CardBody>
</Card>
</Container>)
};

SpaceDetail.propTypes = {};

export { SpaceDetail };