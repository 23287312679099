import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { url } from '../../../Redux/Api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container } from 'react-bootstrap';
import './TeachingRequest.css'

const TeachingRequest = props => {
    const[newState, setNewState] = useState({status:'',data:''});
    const[email, setEmail] = useState('')
    const[name, setName] = useState('')
    const[message, setMessage] = useState('')
    const[company, setCompany] = useState('')
    const[phone, setPhone] = useState('')
    const[loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const user = useSelector(state=>state.auth.user);
const sendMessage = async(e)=>{
    setLoading(true)
    await fetch(`${url}/teaching-request`,{
        mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            company,email:user?user?.email:email,name:user?`${user?.firstName} ${user?.lastName}`:name,message,phone:user?user?.phone1:phone,userId:user?user?._id:null
        })
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            setLoading(false)
            return setNewState({status:'success',data:resp.resp})
        }else if(resp.err){
            setLoading(false)
            return setNewState({status:'failed',data:resp.err})
        }
    }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
}
    return <Container className='teaching-container'>
        <form>
            <p style={{textAlign:'center'}}>{newState.data.length>0?newState.data:'Teaching Request Form'}</p>
        <div className='inputs-wrapper'>
        {
            !user&&<div className="mb-3 row">
            <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10">
              <input className="form-control" onChange={(e) => setName(e.target.value)} id="name" 
              type='text'
              required
              />
            </div>
            </div>
        }
        {
            !user&&<div className="mb-3 row">
            <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
            <div className="col-sm-10">
              <input className="form-control" onChange={(e) => setEmail(e.target.value)} id="email" 
              type='email'
              required
              />
            </div>
            </div>
        }
   {
    !user&& <div className="mb-3 row">
    <label htmlFor="phone" className="col-sm-2 col-form-label">phone</label>
    <div className="col-sm-10">
      <input className="form-control" onChange={(e) => setPhone(e.target.value)} id="phone" 
      type='phone'
      required
      />
    </div>
    </div>
   }
<div className="mb-3 row">
    <label htmlFor="message" className="col-sm-2 col-form-label">Message</label>
    <div className="col-sm-10">
      <textarea className="form-control" rows='5' onChange={(e) => setMessage(e.target.value)} id="message" 
      type='message'
      required
      />
    </div>
    </div>
    <div className="mb-3 row">
    <label htmlFor="company" className="col-sm-2 col-form-label">Company/Business Name</label>
    <div className="col-sm-10">
      <textarea className="form-control" rows='5' onChange={(e) => setCompany(e.target.value)} id="company" 
      type='company'
      required
      />
    </div>
    </div>
  </div>
        {
            loading?
            <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>:<Button type='submit' onClick={sendMessage} className='contact-btn'>Send</Button>
          
        }
        </form>
    </Container>;
};

TeachingRequest.propTypes = {};

export { TeachingRequest };