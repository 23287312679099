import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    course:localStorage.getItem('course')&&JSON.parse(localStorage.getItem('course')),
    status:''
}
const courseSlice = createSlice({
    name:'course',
    initialState,
    reducers:{
        loadCourse(state,action){
            const item = action.payload;
            if(item){
                localStorage.setItem('course',JSON.stringify(item))
                return {
                    course:item,
                    loadCourse:true
                }
            }

        }
    }
})

export const {loadCourse} = courseSlice.actions;
export default courseSlice.reducer