import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setAuthHeader, setHeader, url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getVisitors = createAsyncThunk(
    'visitors/getVisitors',
    async()=>{
        try {
            const response = await axios.get(`${url}/visitors`,setAuthHeader())
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const visitorsSlice = createSlice({
    name:'visitors',
    initialState,
    reducers:{
        loadVisitors(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:builder=>{
        builder.addCase(getVisitors.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getVisitors.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getVisitors.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export const{loadVisitors} = visitorsSlice.actions;
export default visitorsSlice.reducer;