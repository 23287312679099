import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
var VideoCallClient = require('video-call-client');
var videoCallClient = VideoCallClient();

const VideoCall = props => {
    const user = useSelector(state=>state.auth.user);
    videoCallClient.login({ user, families: [] });
 
videoCallClient.on('connected', function() {
  // let's see who's online
  console.log(videoCallClient.users);
});
    return (<div></div>);
};

VideoCall.propTypes = {};

export { VideoCall };