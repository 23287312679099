export const LearnContent = [
    {
        title:'Create Courses',
        body:"In order to create a course, you will have to login to your account, request to be granted th eteacher's permission, once granted, you can proceed to your profile to create courses."
    },
    {
        title:'How To Start Teaching',
        body:"Once you have got people who enrolled in the course(s) you created, you can start lessons with them by clicking the 'Running Courses' button which is on your profile page. If you have more than on course created, kindly create a lesson with the one you intend creating the lesson with, select the space with which you want to host the lesson. But if you haven't acquired a space yet, kindly do so and continue with this process by stating the name of the lesson, and specify its language if it is a coding lesson."
    },
    {
        title:'How To Acquire An Event Space',
        body:`Kindly click`,
    }]

export const FaqContent = [{
    title:'Can I Host Events on Retiny?',
    body:'Yes. You can host events on Retiny. Retiny provides this space for those who want to extend their services to a larger reach.'
},
{
    title:'Can I Teach On Retiny?',
    body:'Yes. You can bring your students here on Retiny Space and have live teaching with them.'
},
{
    title:'Can I get the exact codes my tutor types in an editable format?',
    body:'Yes. You get them codes right on your device and you can edit the codes, copy and paste them.'
}
]

export const AboutContent = [
    {
    title:'About Retiny',
    body:'Retiny is a platform where money is kept in orbits and continuously orbited using space and time as fuel to keep the orbit in its place.'
},
{
    title:'What\'s Retiny',
    body:'Retiny lets you spend your money everyday yet able to save money everyday.'
},
{
    title:'Earning Method',
    body:'Retiny lets you spend your money everyday yet able to save money everyday. It uses time and movement to work, so when either time, it allows people who have big budgets but do not have enough fund to form a groove(group) where each member pays a given sum of money and is able to withdraw with profit.'
},  {
    title:'Groove',
    body:'Retiny lets you spend your money everyday yet able to save some money everyday. It uses time and movement to work, and allows people who have big budgets but do not have enough fund to form a groove(group) where each member contributes a given sum of money and is able to withdraw with profit.'
},  {
    title:'Personal Accounts',
    body:'A personal account is created for all users during signup and they can start investing immediately and withdraw after 24 hours. Investment goes in orbits and grows from one orbit to the another.'
},  {
    title:'',
    body:''
},{
    title:'',
    body:''
},  {
    title:'',
    body:''
},
]