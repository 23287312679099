import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setAuthHeader, url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getContacts = createAsyncThunk(
    'contacts/getContacts',
    async()=>{
        try {
            const response = await axios.get(`${url}/contacts`,setAuthHeader())
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const contactsSlice = createSlice({
    name:'contacts',
    initialState,
    reducers:{
        loadcontacts(state,action){
            return {...state,items:action.payload}
        }
    },
   
    extraReducers:builder=>{
        builder.addCase(getContacts.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getContacts.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getContacts.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export const{loadcontacts} = contactsSlice.actions;
export default contactsSlice.reducer;