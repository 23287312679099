import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:[],
    status:''
}
export const getEnrolments = createAsyncThunk(
    'enrolments/getEnrolments',
    async()=>{
        try {
          const response = await axios.get(`${url}/enrolments`);
          return response.data.resp
        } catch (error) {
            return 
        }
    }
)
const enrolmentsSlice = createSlice({
    name:'enrolments',
    initialState,
    reducers:{},
    extraReducers:builder=>{
        builder.addCase(getEnrolments.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getEnrolments.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getEnrolments.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export default enrolmentsSlice.reducer;