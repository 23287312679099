import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardText, CardTitle, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import moment from 'moment';

const WorkDetail = props => {
const{workId} = useParams();
const works = useSelector(state=>state.works.items);
const[work, setWork] = useState(JSON.parse(localStorage.getItem('work')));

useEffect(()=>{
    if(works?.length>0){
      let a = works?.filter(filt=>filt._id.toString()===workId.toString());
    setWork(a[0]);
    localStorage.setItem('work',JSON.stringify(a[0]))
    }
},[workId]);
useEffect(()=>{
  console.log(work)
},[work])
    return(<Container>
  <Card style={{margin:'5px'}}>
<CardBody className="card-body">
  <CardTitle className="card-title">{work?.title}</CardTitle>
  <CardText className="card-text-work">{work?.description}</CardText>
  <CardText className="card-text-work">ID {work?._id}</CardText>
  <CardText className="card-text-work">Submission Date {moment(work?.submissionDate).format('DD-MM-YYYY hh:mm:ss')}</CardText>
  {work?.code?.length>0&&<CodeMirror
             selection={{
                 ranges: [{
                   anchor: {ch: 2, line: 5},
                   head: {ch: 37, line: 5}
                 }],
                 focus: true // defaults false if not specified
               }}
               onSelection={(editor, data) => {}}
             value={work?.code}
             options={{
               mode: work?.language,
               theme: 'material',
               lineNumbers: true
             }}
           />}
</CardBody>
</Card>
</Container>)
};

WorkDetail.propTypes = {};

export { WorkDetail };