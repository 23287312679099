import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const user = useSelector(state=>state.auth.user)
    const {pathname} = useLocation()
    const noFooter = [`/${user?._id}/:amount/:paymentId/:type/withdrawal`]
    
    if(noFooter.some(item=>item===pathname))
    return null
    return (
        <div className='footer-main'>
         <footer className='footer'>
                <div className='footer-top'>
                <div className='left-footer'>
                <p className='left-footer-title'>Knowledge</p>
                <div className='left-footer-content-one'>
                   
                <div className='left-footer-content-one-wrapper'>
                    <Link to='/learn'className='left-footer-content-one-item'>
                    <p className='footer-sub-text'>Learn</p>
                    </Link>
                    </div>
                    <div className='left-footer-content-one-wrapper'>
                    <Link to='/faq' className='left-footer-content-one-item'>                    
                        <p className='footer-sub-text'>FAQ</p>
                    </Link>
                    </div>
                </div>
                </div>
                <div className='middle-footer'>
                <p className='left-footer-title'>Company</p>
                <div className='left-footer-content-one'>
                    <div className='left-footer-content-one-wrapper'>
                    {user?<a href={`/${user?._id}/profile`} className='left-footer-content-one-item'>
                        <p className='footer-sub-text'>Get Started</p>
                    </a>:<a href={`/login`} className='left-footer-content-one-item'>
                        <p className='footer-sub-text'>Get Started</p>
                    </a>}
                    </div>
                    <div className='left-footer-content-one-wrapper'>
                    <Link to={`/contact-us`} className='left-footer-content-one-item'>
                        <p className='footer-sub-text'>Contact us</p>
                    </Link>
                    </div>
                </div>
                </div>

                <div className='middle-footer'>
                <p className='left-footer-title'>Useful Links</p>
                <div className='left-footer-content-one'>
                    <div className='left-footer-content-one-wrapper'>
                    
                    <div className='left-footer-content-one-item'>
                        <a href= "mailto: help@res.retiny.org" className='footer-sub-text'>help@res.retiny.org</a>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                <div className='footer-bottom'>
<p className='copyright'>copyright &copy; {moment(new Date()).year()} | powered by retiny technology limited</p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;