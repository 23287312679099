import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Card, CardText, Container } from 'react-bootstrap';

const PasswordSent = props => {
    const{email} = useParams()
    return <Container>
            <CardText>Kindly click the link sent to {email} to reset your password.</CardText>
        </Container>;
};

PasswordSent.propTypes = {};

export { PasswordSent };