import { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import {UnControlled as CodeMirror} from 'react-codemirror2'

function Coders() {
const[values, setValues] = useState('');

  return (
    <Container>
    <Row style={{position:'relative'}}>
        <CodeMirror
    selection={{
        ranges: [{
          anchor: {ch: 8, line: 5},
          head: {ch: 37, line: 5}
        }],
        focus: true // defaults false if not specified
      }}
      onSelection={(editor, data) => {}}
    value={values}
    options={{
      mode: 'xml',
      theme: 'material',
      lineNumbers: true
    }}
    onChange={(editor, data, value) => {
        setValues(value)
    }}
  />
    </Row>
        
    </Container>
  )
}

export default Coders;
