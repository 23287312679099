import React, { useState, useEffect } from 'react';
import Editor from './Editor';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './Workbook.css';

function Workbook() {
  const{type} = useParams()
  const [html, setHtml] = useLocalStorage('html', '')
  const [css, setCss] = useLocalStorage('css', '')
  const [js, setJs] = useLocalStorage('js', '')
  const [srcDoc, setSrcDoc] = useState('')
  

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSrcDoc(`
        <html>
          <body>${html}</body>
          <style>${css}</style>
          <script>${js}</script>
        </html>
      `)
    }, 250)
    
    return () => clearTimeout(timeout)
  }, [html, css, js]);
  const clearLog = ()=>{
    localStorage.removeItem('html-workbook');
    localStorage.removeItem('css-workbook');
    localStorage.removeItem('js-workbook');
    window.location.reload()
  }
const navigate = useNavigate();
  return (
    <Container className='workbook-container'>
    <Row style={{position:'relative'}}>
    {/* <div className="pane top-pane"></div> */}
    
        <Editor
          language="xml"
          displayName="HTML"
          value={html}
          onChange={setHtml}
        />
        <Editor
          language="css"
          displayName="CSS"
          value={css}
          onChange={setCss}
        />
        
        <Editor
          language="javascript"
          displayName="JS"
          value={js}
          onChange={setJs}
        />
      
      <button onClick={clearLog} className='clear-log-btn'>Clear log</button>
      <div className="pane">
        <iframe
          srcDoc={srcDoc}
          title="output"
          sandbox="allow-scripts"
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </div>
      <Col xl={6} xs={8} style={{position:'absolute',top:'-30px'}}>
      <FaArrowLeft style={{marginRight:'20px',color:'gray',cursor:'pointer'}} onClick={()=>navigate(-1)}/>
      {/* <Anchor style={{marginRight:'20px',color:'gray'}} href={`/code/:blogCourse}`}><FaShareAlt/></Anchor> */}
      {type.toUpperCase()} <em>Practice Area</em>
      </Col>
    </Row>
        
    </Container>
  )
}

export default Workbook;