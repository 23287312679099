import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    community:localStorage.getItem('communityQuestion')&&JSON.parse(localStorage.getItem('communityQuestion')),
    status:''
}

const communityQuestionSlice = createSlice({
    name:'communityQuestion',
    initialState,
    reducers:{
        loadCommunityQuestion(state,action){
            const item = action.payload;
            if(item){
                localStorage.setItem('communityQuestion',JSON.stringify(item))
                return {
                    community:item,
                    loadSlice:true
                }
            }

        }
    }
})
export const {loadCommunityQuestion} = communityQuestionSlice.actions;
export default communityQuestionSlice.reducer