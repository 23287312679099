import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './Community.css';
import { Button, Card, CardLink, CardText, CardTitle, Container, FormControl, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { FaCheck, FaThumbsUp } from 'react-icons/fa';
import { url } from './Redux/Api';
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

const Community = props => {
    const{blogId} = useParams();
    const blogs = useSelector(state=>state.blogs.items)
    const users = useSelector(state=>state.users.items)
    const communityQuestions = useSelector(state=>state.communityQuestions.items);
    const communityComments = useSelector(state=>state.communityComments.items);  
    const[showForm, setShowForm] = useState(false);
    const[newState, setNewState] = useState({status:'',data:''});
    const[comment, setComment] = useState('');
    const[questionTitle, setQuestionTitle] = useState('');
    const[questionBody, setQuestionBody] = useState('');
    const[questionSrc, setQuestionSrc] = useState('');
    const[codes, setCodes] = useState('');
    const[loading, setLoading] = useState(false);
    const[blog, setBlog] = useState({})
    const[communityQuestion, setCommunityQuestion] = useState({})

    const fileUpload = async (a)=>{
        const data = a.target.files[0];
        const base64 = await Converter(data);
        setQuestionSrc(base64);
      }
      const Converter = (e)=>{
        return new Promise((resolve,reject)=>{
          const reader = new FileReader()
          reader.readAsDataURL(e)
          reader.onload = ()=>{
            resolve(reader.result)
          }
          reader.onerror = (error)=>{
            reject(error)
          }
        })
      }

    useEffect(()=>{
        let a = communityQuestions?.filter(filt=>filt.blogId?.toString()===blogId?.toString())[0]
        let b = blogs?.filter(filt=>filt._id?.toString()===blogId?.toString())[0]
        setBlog(b)
        setCommunityQuestion(a)
        console.log('bloger',b)
    },[blogId]);

    const handleCommentLike = (e)=>{
        setLoading(true);
        async function fetchData(){
            await fetch(`${url}/like-community-comment`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify({
                    commentParentId:e?._id
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    window.location.reload()
                    setLoading(false)
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    setLoading(false)
                    return setNewState({status:'failed',data:resp.err})
                }
            }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
        }
        fetchData()
    }
    const handleComment = (e)=>{
        e.preventDefault();
        setLoading(true);
        async function fetchData(){
            await fetch(`${url}/comment-community-question`,{
                mode:'cors',
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify({
                    comment,commentParentId:e?._id,codes
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    window.location.reload()
                    setLoading(false)
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    setLoading(false)
                    return setNewState({status:'failed',data:resp.err})
                }
            }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
        }
        fetchData()
    }

    const handleAsk = (e)=>{
        e.preventDefault()
        setLoading(true);
        async function fetchData(){
            await fetch(`${url}/ask-community-question`,{
                mode:'cors',
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify({
                    questionTitle,questionBody,codes,blogId:blog,questionSrc,blogId
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    window.location.reload()
                    setLoading(false)
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    setLoading(false)
                    return setNewState({status:'failed',data:resp.err})
                }
            }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
        }
        fetchData()
    }

    return (<Container className='community-container'>
        <h1 className='community-page-title'>Retiny Learning Community</h1>
<div>
    {communityQuestions?.filter(filt=>filt?.blogId?.toString()===blog?._id?.toString()).length>0?
    <>
    <h3>{communityQuestions?.filter(filt=>filt?.blogId?.toString()===blog?._id?.toString()).length}Questions</h3>
    <h1>Author {users.filter(filt=>filt?._id?.toString()===communityQuestion?.author?.toString())[0]?.userName} asked</h1>
    <h5>{communityQuestion?.name} on {moment(communityQuestion?.date).format('DD-MM-YYYY HH:MM:SS')}</h5>
    </>
    :<h3>No discussions yet.</h3>}
    {communityQuestions?.length>0&&
        communityQuestions?.filter(filt=>filt?.blogId?.toString()===blog?._id?.toString())?.map((itm,ind)=>{
            return(<div className='community-items' key={ind}>
                <div>
                    <h5>{itm?.questionTitle}</h5>
                    <p>{itm?.questionBody}</p>
                        <p>{itm?.codes}</p>
                        <Link to={`/${blogId}/${itm?._id}`}>View discussions</Link>
                </div>
            </div>)
        })
    }
</div>

<div style={{marginTop:'20px'}}>
<h5 style={{color:'var(--danger-1)'}}>Want to ask a question on</h5>
<p>{blog?.blogTitle}?</p>
<form onSubmit={handleAsk} className='community-question-form'>
            <textarea as='textarea' onChange={a=>setQuestionTitle(a.target.value)} className='question-input' />
            <textarea as='textarea' onChange={a=>setQuestionBody(a.target.value)} className='question-input' />
                <input type='file' onChange={fileUpload} />
            <CodeMirror
             selection={{
                 ranges: [{
                   anchor: {ch: 2, line: 5},
                   head: {ch: 37, line: 5}
                 }],
                 focus: true // defaults false if not specified
               }}
               onSelection={(editor, data) => {}}
             value={codes}
             options={{
               mode: blog?.language,
               theme: 'material',
               lineNumbers: true
             }}
             onChange={(editor, data, value) => {
                 setCodes(value)
             }}
           />
            <Button type='submit' className='contact-btn'>Ask a question</Button>
        </form>
</div>
    </Container>);
};

Community.propTypes = {};

export { Community };