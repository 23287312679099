import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { home_url } from '../../../Redux/Api';
import { useNavigate } from 'react-router-dom';
import { Container, Form, FormControl, Row } from 'react-bootstrap';

const ChangePassword = props => {
    const[newState, setNewState] = useState({status:'',data:''});
    const[email, setEmail] = useState('')
    const[loading, setLoading] = useState(false);
    const navigate = useNavigate();

const passwordChnage = async(e)=>{
    setLoading(true)
    await fetch(`${home_url}/password-change`,{
        mode:'cors',
        method:'post',
        headers:{
            'Content-Type':'application/json',
        },
        body:JSON.stringify({
            email
        })
    }).then(res=>res.json())
    .then(resp=>{
        if(resp.resp){
            return navigate(`/${email}/password-sent`)
        }else if(resp.err){
            setLoading(false)
            return setNewState({status:'failed',data:resp.err})
        }
    }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
}
    return <Container className='auth-container'>
        <Form>
            <p style={{textAlign:'center'}}>{newState.data.length>0?newState.data:'Change Password'}</p>
        <div className='inputs-wrapper'>
        <Row className="mb-3 row">
    <span>Email</span>
    <FormControl  
      onChange={(e) => setEmail(e.target.value)} id="email" 
      type='email'
      required
      />
    </Row>
  </div>
        {
            loading?
            <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>:<a type='submit' onClick={passwordChnage} role='button' className='btn'>Continue</a>
        }
        </Form>
    </Container>;
};

ChangePassword.propTypes = {};

export { ChangePassword };