import React, { useState } from 'react'
// import 'codemirror/lib/codemirror.css'
// import 'codemirror/theme/material.css'
// import 'codemirror/mode/xml/xml'
// import 'codemirror/mode/javascript/javascript'
// import 'codemirror/mode/css/css'
//import { Controlled as ControlledEditor } from 'react-codemirror2'
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa'

export default function Editor(props) {
  const {
    // initialState,
    language,
    displayName,
    value,
    onChange
  } = props
  const [open, setOpen] = useState(true)

  function handleChange(editor, data, value) {
    onChange(value)
  }

  return (
    <div className={`editor-container ${open ? '' : 'collapsed'}`}>
      <div className="editor-title">
        {displayName}
        <button
          type="button"
          className="expand-collapse-btn"
          onClick={() => setOpen(prevOpen => !prevOpen)}
        >
          {
            open?<FaCompressAlt/>:<FaExpandAlt/>
          }
        </button>
      </div>
      {/* <ControlledEditor
        onBeforeChange={handleChange}
        value={value}
        className="code-mirror-wrapper"
        options={{
          lineWrapping: true,
          lint: true,
          mode: language,
          theme: 'material',
          lineNumbers: true
        }}
      /> */}
      <CodeMirror
             selection={{
                 ranges: [{
                   anchor: {ch: 2, line: 5},
                   head: {ch: 37, line: 5}
                 }],
                 focus: true // defaults false if not specified
               }}
               onSelection={(editor, data) => {}}
             value={value}
             className="code-mirror-wrapper"
             options={{
               mode: language,
               theme: 'material',
               lineSeparator:true,
               lineNumbers: true
             }}
             onChange={handleChange}
           />
    </div>
  )
}
