import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
import { clientUrl, home_url } from "../Api";

const initialState = {
    user:null,
    token:null,
    registerStatus:'',loginStatus:'',
    registerError:'',loginError:'',getUserStatus:'',getUserError:''
}
export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (user,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${home_url}/register`,{
                userName:user.userName,
                email:user.email,
                firstName:user.firstName,
                lastName:user.lastName,
                country:user.country,
                phone:user.phone,
                referrer:user.referrer,
                password:user.password,
                confirmedPassword:user.confirmedPassword
            })
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async(user,{rejectWithValue})=>{
        try {
            const response = await axios.post(`${home_url}/login`,{
                email:user.email,
                password:user.password,
                agent:clientUrl
            })
             return response.data.user
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

export const getUser = createAsyncThunk(
    'auth/getUser',
    async(id,{rejectWithValue})=>{
        try {
            const response = await axios.get(`${home_url}/main-profile/${id.token}`)
            return response.data.resp
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)
const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        loadUser(state,action){
            let data = action.payload;
            const tokenData = data?.token
            const userData = data?.user
            if(tokenData && userData){
                localStorage.setItem('user',JSON.stringify(tokenData))
                localStorage.setItem('token',JSON.stringify(tokenData))
                return {...state,user:userData,token:tokenData}
            }

        },
        logOut:(state)=>{
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            return {
                ...state,
                user:null,
                token:null,
                
            }
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(registerUser.pending,(state,action)=>{
            return {...state,registerStatus:'pending'}
        })
        builder.addCase(registerUser.fulfilled,(state,action)=>{
            if(action.payload){
                const user = action.payload
                return {...state,token:action.payload,user:user,registerStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(registerUser.rejected,(state,action)=>{
            return {...state,registerStatus:'rejected',registerError:action.payload}
        })
        builder.addCase(loginUser.pending,(state,action)=>{
            return {...state, loginStatus:'pending'}
        })
        builder.addCase(loginUser.fulfilled,(state,action)=>{
            if(action.payload){
                localStorage.setItem('token',JSON.stringify(action.payload.token))
                localStorage.setItem('user',JSON.stringify(action.payload.user))
                return {...state,token:action.payload.token,user:action.payload.user,loginStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(loginUser.rejected,(state,action)=>{
            return {...state,loginStatus:'rejected',loginError:action.payload}
        })
        builder.addCase(getUser.pending,(state,action)=>{
            return {...state,getUserStatus:'pending'}
        })
        builder.addCase(getUser.fulfilled,(state,action)=>{
            if(action.payload){
                return {...state,user:action.payload,token:action.payload,getUserStatus:'success'}
            }else{
                return state
            }
        })
        builder.addCase(getUser.rejected,(state,action)=>{
            return {...state,getUserStatus:'rejected',getUserError:action.payload}
        })
    }
})

export const {loadUser, logOut} = authSlice.actions;
export default authSlice.reducer;