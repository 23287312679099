import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { url } from "../Api";

const initialState = {
    items:localStorage.getItem('spaces')?JSON.parse(localStorage.getItem('spaces')):[],
    status:''
}
export const getSpaces = createAsyncThunk(
    'spaces/getSpaces',
    async()=>{
        try {
          const response = await axios.get(`${url}/spaces`);
          localStorage.setItem('spaces',JSON.stringify(response.data.resp));
          return response.data.resp;
        } catch (error) {
            return 
        }
    }
)

const spacesSlice = createSlice({
    name:'spaces',
    initialState,
    reducers:{
        loadSpaces(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:builder=>{
        builder.addCase(getSpaces.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getSpaces.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getSpaces.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export const{loadSpaces} = spacesSlice.actions;
export default spacesSlice.reducer;