import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    blog:localStorage.getItem('blog')&&JSON.parse(localStorage.getItem('blog')),
    status:''
}
const blogSlice = createSlice({
    name:'blog',
    initialState,
    reducers:{
        loadSlice(state,action){
            const item = action.payload;
            if(item){
                localStorage.setItem('blog',JSON.stringify(item))
                return {
                    blog:item,
                    loadSlice:true
                }
            }

        }
    }
})

export const {loadSlice} = blogSlice.actions;
export default blogSlice.reducer