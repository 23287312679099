import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { css } from '@emotion/css';
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import './Blog.css'


const Blog = props => {
  const blogs = useSelector(state=>state.blogs.items);
  const{blogCourse,blogId} = useParams();
  const[text, setText] = useState('')
  const [html, setHtml] = useLocalStorage('html', '')
  const [css, setCss] = useLocalStorage('css', '')
  const [js, setJs] = useLocalStorage('js', '')
  const [srcDoc, setSrcDoc] = useState('')

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setSrcDoc(`
  //       <html>
  //         <body>${html}</body>
  //         <style>${css}</style>
  //         <script>${js}</script>
  //       </html>
  //     `)
  //   }, 250)

  //   return () => clearTimeout(timeout)
  // }, [html, css, js])
  // useEffect(()=>{
  //   console.log('values',html,css,js)
  // },[html,css,js])

const navigate = useNavigate();

    return <Container className='blog-container'>
      <Row>
        <Col xl={6} md={12}>
        {blogCourse?<h4>{blogCourse?.toUpperCase()}</h4>:<h4>Courses</h4>}
        </Col>
      </Row>
        <Row>
        {
        blogs.filter(filt=>{
          if(blogCourse&&blogCourse.length>0){
            return filt.blogCourse.toLowerCase()===blogCourse?.toLowerCase()
          }
          return filt
        }).map((item,index)=>{
          return(
            <Accordion key={index} defaultActiveKey={blogId}>
      <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>{!blogCourse&&<><em>{item?.blogCourse}</em> -</>}{item?.blogTitle}</Accordion.Header>
        <Accordion.Body>
          <Link to={`/community/${item?._id}`}>Discuss {item?.blogTitle} with others.</Link>
        {
  item.codes.map((itm,ind)=>{
    return(<div key={ind}>
      <p>kill{itm}</p>
      <CodeMirror
             selection={{
                 ranges: [{
                   anchor: {ch: 2, line: 5},
                   head: {ch: 37, line: 5}
                 }],
                 focus: true // defaults false if not specified
               }}
               onSelection={(editor, data) => {}}
             value={text.length>0?text:itm.code}
             options={{
               mode: item?.blogCourse,
               theme: 'material',
               lineNumbers: true
             }}
             onChange={(editor, data, value) => {
                setText(value)
             }}
           />  
    </div>)
  })
}
{item?.blogBody}
       <CodeMirror
             selection={{
                 ranges: [{
                   anchor: {ch: 2, line: 5},
                   head: {ch: 37, line: 5}
                 }],
                 focus: true // defaults false if not specified
               }}
               onSelection={(editor, data) => {}}
             value={item?.codes[index]}
             options={{
               mode: item?.blogCourse,
               theme: 'material',
               lineNumbers: true
             }}
             onChange={(editor, data, value) => {
                setText(value)
             }}
           />  
       {/* <div className="pane">
        <iframe
          srcDoc={item?.codes[0]}
          title="output"
          sandbox="allow-scripts"
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </div>  */}
          
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
          )
        })
       }
        </Row>
    </Container>;
};

Blog.propTypes = {};

export { Blog };