import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './Community.css';
import { Button, Card, CardLink, CardTitle, Container, FormControl, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { FaCheck, FaThumbsUp } from 'react-icons/fa';
import { url } from './Redux/Api';
import {UnControlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

const CommunityComments = props => {
    const{blogId,questionId} = useParams();
    const blogs = useSelector(state=>state.blogs.items)
    const users = useSelector(state=>state.users.items)
    const user = useSelector(state=>state.auth.user)
    const communityQuestions = useSelector(state=>state.communityQuestions.items);
    const communityComments = useSelector(state=>state.communityComments.items);  
    const[showForm, setShowForm] = useState(false);
    const[newState, setNewState] = useState({status:'',data:''});
    const[comment, setComment] = useState('');
    const[questionTitle, setQuestionTitle] = useState('');
    const[questionBody, setQuestionBody] = useState('');
    const[commentSrc, setCommentSrc] = useState('');
    const[codes, setCodes] = useState('');
    const[loading, setLoading] = useState(false);
    const[blog, setBlog] = useState({})
    const[communityQuestion, setCommunityQuestion] = useState({})

    const fileUpload = async (a)=>{
        const data = a.target.files[0];
        const base64 = await Converter(data);
        setCommentSrc(base64);
      }
      const Converter = (e)=>{
        return new Promise((resolve,reject)=>{
          const reader = new FileReader()
          reader.readAsDataURL(e)
          reader.onload = ()=>{
            resolve(reader.result)
          }
          reader.onerror = (error)=>{
            reject(error)
          }
        })
      }

    useEffect(()=>{
        let a = communityQuestions?.filter(filt=>filt.blogId?.toString()===blogId?.toString())[0]
        let b = blogs?.filter(filt=>filt._id?.toString()===blogId?.toString())[0]
        setBlog(b)
        setCommunityQuestion(a)
        console.log('bloger',b)
    },[blogId]);

    const handleCommentLike = (e)=>{
        setLoading(true);
        async function fetchData(){
            await fetch(`${url}/like-community-comment`,{
                mode:'cors',
                method:'put',
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify({
                    commentParentId:e?._id
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    window.location.reload()
                    setLoading(false)
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    setLoading(false)
                    return setNewState({status:'failed',data:resp.err})
                }
            }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
        }
        fetchData()
    }
    const handleComment = (e)=>{
        e.preventDefault();
        setLoading(true);
        async function fetchData(){
            await fetch(`${url}/comment-community-question`,{
                mode:'cors',
                method:'post',
                headers:{
                    'Content-Type':'application/json',
                },
                body:JSON.stringify({
                    comment,codes,blogId,questionId,commentParentId:e?._id?.length>0?e?._id:'',commentSrc
                })
            }).then(res=>res.json())
            .then(resp=>{
                if(resp.resp){
                    window.location.reload()
                    setLoading(false)
                    return setNewState({status:'success',data:resp.resp})
                }else if(resp.err){
                    setLoading(false)
                    return setNewState({status:'failed',data:resp.err})
                }
            }).catch(err=>{setLoading(false);setNewState({status:'failed',data:err.message})})
        }
        fetchData()
    }

    return (<Container className='community-container'>
        <h1 className='community-page-title'>Retiny Learning Community</h1>
        <p className='community-page-course-title'>{blog?.blogTitle}</p>
<div>
    <h1 className='community-title'>Author {users.filter(filt=>filt?._id?.toString()===communityQuestion?.author?.toString())[0]?.userName} asked</h1>
    <h5>{communityQuestion?.name} on {moment(communityQuestion?.date).format('DD-MM-YYYY HH:MM:SS')}</h5>
    {user?<form onSubmit={handleComment} className='form'>
    <FormControl
    as='textarea'
    placeholder='Comment..'
    onChange={(e)=>setComment(e.target.value)}
    />
    <input type='file' onChange={fileUpload}/>
    {loading?<Spinner/>:
    <>
    <Button type='submit' className='contact-btn'>Send</Button>
    </>
    }
</form>:<Link to='/login'>Login to participate</Link>}
    {communityComments?.length>0?
        communityComments?.filter(filt=>filt?.blogId?.toString()===blog?._id?.toString())?.map((itm,ind)=>{
            return(<ListGroup key={ind}>
                <ListGroupItem>
                    <Card>
                        <CardTitle>{itm?._id}</CardTitle>
                        <CardTitle>{itm?.text}</CardTitle>
                        <CardTitle>{itm?.codes}</CardTitle>
                        {user?<form onSubmit={handleComment} className='form'>
    <FormControl
    as='textarea'
    placeholder='Comment here'
    onChange={(e)=>setComment(e.target.value)}
    />
    <input type='file' onChange={fileUpload}/>
    {loading?<Spinner/>:<Button type='submit' className='contact-btn'>Send</Button>}
</form>:<Link to='/login'>Login to participate</Link>}
                        <FaThumbsUp onClick={()=>handleCommentLike(itm)}/>
                    </Card>
                </ListGroupItem>
            </ListGroup>)
        })
    :''}
</div>
    </Container>);
};

CommunityComments.propTypes = {};

export { CommunityComments };