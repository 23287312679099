import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { setHeader, url } from "../Api"

const initialState = {
    items:[],
    status:''
}

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async()=>{
        try {
            const response = await axios.get(`${url}/users`,setHeader())
            if(response){
                return response.data.resp
            }else{
                return 
            }
        } catch (error) {
            return
        }
    }
)
const usersSlice = createSlice({
    name:'users',
    initialState,
    reducers:{
        loadUsers(state,action){
            return {...state,items:action.payload}
        }
    },
    extraReducers:builder=>{
        builder.addCase(getUsers.pending,(state,action)=>{
            return {...state,status:'pending'}
        })
        builder.addCase(getUsers.rejected,(state,action)=>{
            return {...state, status:'rejected'}
        })
        builder.addCase(getUsers.fulfilled,(state,action)=>{
            return {...state,items:action.payload,status:'fulfilled'}
        })
    }
})

export const{loadUsers} = usersSlice.actions;
export default usersSlice.reducer;